.header {
  position: sticky;
  top: 0;
  width: 150px;
  display: flex;
  align-items: center;
  padding: 10px 0px 15px 0px;
  z-index: 1000;
  width: 70%;
  background: #0d1b2a;
  margin-left: 30px;
  border-bottom: 1px solid #00264E;
}

.logo {
  height: 50px;
}

.dropdownContainer {
  position: relative;
  display: flex;
  padding: 15px 10px;
  margin-left: 10px;
  border-radius: 25px;
  background: #041010;
  cursor: pointer;
  margin-top: 10px;
  font-size: 14px;
}

.selectedOption {
  color: #beef9e;
}

.downArrow {
  height: 10px;
  cursor: pointer;
  padding: 0px 5px;
  margin-top: 5px;
}

.dropdownMenu {
  position: absolute;
  z-index: 1;
  background-color: #041010;
  border-radius: 10px;
  width: 200px;
  top: 55px;
  padding: 10px;
}

.dropdownItem {
  padding: 8px;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  justify-content: space-between;
}

.tickIcon {
  height: 16px;
  margin-left: 8px;
}
