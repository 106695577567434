.file-drop {
    /* relatively position the container bc the contents are absolute */
    position: relative;
    /* height: 100%; */
    /* width: 100%; */
    height: min(100%, 300px);
    width: min(100%, 500px);
}

.file-drop>.file-drop-target {
    /* basic styles */
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    /* horizontally and vertically center all content */
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;

    flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;

    align-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;

    justify-content: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;

    align-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;

    text-align: center;

    border-radius: 8px;
}

.file-drop>.file-drop-target.file-drop-dragging-over-frame {
    /* overlay a black mask when dragging over the frame */
    background-color: #0B1723;
    box-shadow: none;
    z-index: 50;
    opacity: 1;

    /* typography */
    color: white;
    border: 2px solid #7357EB;
}

.file-drop>.file-drop-target.file-drop-dragging-over-target {
    /* turn stuff purple when we are dragging over the target */
    color: #7357EB;
    box-shadow: 0 0 13px 3px #7357EB;
}