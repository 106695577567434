@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-montserrat: Montserrat;

  /* font sizes */
  --font-size-base: 16px;
  --font-size-sm: 14px;
  --font-size-xs: 12px;
  --font-size-13xl: 32px;

  /* Colors */
  --cw-purple: #7357eb;
  --dark-content-1: #fff;
  --dark-gray-04: #292929;
  --dark-gray-01: #171717;
  --light-alpha-12: rgba(255, 255, 255, 0.8);
  --light-alpha-10: rgba(255, 255, 255, 0.45);
  --light-alpha-11: rgba(255, 255, 255, 0.65);
  --dark-alpha-10: rgba(0, 0, 0, 0.48);
  --dark-alpha-11: rgba(0, 0, 0, 0.56);
  --dark-content-5: #a2a2a2;
  --dark-content-3: #d1d1d1;
  --light-content-1: #000;
  --light-gray-04: #ededed;
  --light-content-5: #5d5d5d;
  --light-indigo-09: #3c61dd;

  /* Gaps */
  --gap-3xs: 10px;
  --gap-base: 16px;
  --gap-11xs: 2px;
  --gap-xs: 12px;
  --gap-5xs: 8px;
  --gap-9xs: 4px;

  /* Paddings */
  --padding-7xs: 6px;
  --padding-5xl: 24px;
  --padding-base: 16px;
  --padding-3xs: 10px;
  --padding-xs: 12px;
  --padding-5xs: 8px;
  --padding-9xs: 4px;

  /* Border radiuses */
  --br-10xs: 3px;
  --br-xs: 12px;
  --br-31xl: 50px;
  --br-13xl: 32px;
  --br-xl: 20px;

  /* Effects */
  --shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
  --blur: blur(40px);
}

#chat-icon {
  position: fixed;
  bottom: 20px; /* Adjust the distance from the bottom as needed */
  right: 20px; /* Adjust the distance from the right as needed */
  background-color: #007bff; /* Background color of the icon */
  color: #fff; /* Text color of the icon */
  padding: 10px;
  border-radius: 50%; /* Makes the icon circular */
  cursor: pointer;
  width: 50px;
}
a{
  text-decoration: none!important;
}
select{
  border-radius: var(--br-13xl);
  border: 1px solid var(--cw-purple);
  box-sizing: border-box;
  height: 144px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-7xs) var(--padding-base) var(--padding-7xs) var(--padding-7xs);
  /* gap: var(--gap-5xs); */
  background: transparent;
  color: #fff;
  font-size: var(--font-size-base);
}

select option {
  background-color: #ffffff; /* Set the background color of options */
  color: #000000; /* Set the text color of options */
  font-size: var(--font-size-xs);
}
.dropdownButton {
  padding: 5px 10px;
  cursor: pointer;
  background-color: #f1f1f1;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.dropdownMenu {
  position: absolute;
  z-index: 1;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 150px;
}

.dropdownMenuItem {
  padding: 8px;
  cursor: pointer;
}

.dropdownMenuItem:hover {
  background-color: #f1f1f1;
}
.tickIcon {
  height: 16px;
  margin-left: 8px;
}
h2{
  font-size: 15px;
}
ul{
  margin-left: 0px;
  padding-left: 14px;
  list-style-type: disc;
}
ol{
  margin-left: 0px;
  padding-left: 14px;
  list-style-type: disc;
}
li{
  font-size: 13px;
  margin-bottom: 10px;
}

li::marker {
  color: #beef9e;
}
text:hover{
  color: rgb(190 239 158)!important;
  fill: rgb(190 239 158)!important;
}
.border-header-custom{
  border: 1px solid #382F5C; 
}
.border-header-custom-before::before{
  border-left: 1px solid #382F5C; 
}
.header-border-margin{
  border-bottom: 1px solid #382F5C;
  margin: 0px 2rem;  
}
html{
  background: #0d1b2a;
}
