/* src/styles/thoughtProvokingQuestions.module.css */
.thoughtProvokingQuestionsContainer {
  /* position: absolute; */
  /* right: 0; */
  /* top: 48%; */
  /* display: flex; */
  /* flex-wrap: wrap; */
  /* gap: 10px; */
  /* margin: 10px 0px; */
  /* flex-direction: column; */
  /* justify-content: center; */
  margin-bottom: 15px;
  /* margin-right: 15px; */
  padding: 0px 10px;
}
  
.thoughtProvokingQuestionBox {
  background-color: #0D1B2A;
  padding: 10px 10px;
  border-radius: 10px;
  /* width: 250px; */
  border: 1px solid rgb(115 87 235 / 30%);
  font-size: 12px;
  color: #fff;
  max-height: 338px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow: scroll;
}
.thoughtProvokingQuestionBox ol li::marker {
  color: #beef9e;
}

.thoughtProvokingQuestionBox ol li{
  font-size: 14px;
}
