/* src/styles/suggestedQuestions.module.css */
.suggestedQuestionsContainer {
    /* position: absolute; */
    /* right: 0; */
    /* top: 0; */
    /* display: flex; */
    /* flex-wrap: wrap; */
    /* gap: 10px; */
    margin: 10px 0px;
    /* flex-direction: column; */
    /* justify-content: center; */
    margin-bottom: 15px;
    padding: 0 10px;
  }
  
  .suggestionBox {
    background-color: #0D1B2A;
    padding: 5px 10px;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.5s;
    /* width: 250px; */
    border: 1px solid rgb(115 87 235 / 30%);
    /* font-size: 12px; */
    color: #fff;
    /* max-height: 70px; */
    min-height: 70px;
    display: flex;
    align-items: center;
    /* overflow: scroll; */
    margin-bottom: 15px;
    /* height: auto; */
    overflow: hidden;
    transition: font-size 0.3s ease;
  }
  
  .suggestionBox:hover {
    background-color: transparent;
    color: #ffffff;
  }
  /* .suggestedQuestionsDiv > div:nth-child(3) { */
    /* margin-right: 0px; */
  /* } */
  .suggestionBoxIcon{
    height: 42px;
    margin: 0px 5px 0px 0px;
    align-items: flex-start;
    max-width: fit-content;
  }