.recommended_actions {
    border-radius: 10px;
    border: 1px solid #7357eb4d;
    color: #fff;
    max-height: 338px;
}

.recommended_actions ol, .graph_summary ul {
    list-style-type: none;
    padding: revert;
    padding-left: 0;
    margin-left: 0;
}

.recommended_actions ol li {
    padding-left: 1rem;
    text-indent: -12px;
}

.recommended_actions ol li::before, .graph_summary ul li:before {
    content: "";
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #BEEF9E;
    outline: 2px solid #BEEF9E4D;
    box-sizing: content-box;
    transform: translateX(-10px);
}

.graph_summary {
    color: #fff;
    padding: 0;
}

.graph_summary h2 {
    font-size: 1rem;
    font-weight: 500;
}

.graph_summary ul li{
    padding-left: 0.7rem;
}